import React from 'react'

interface QuotationProps {
  className: string
}

const Quotation = ({ className }: QuotationProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="78"
      height="61"
      viewBox="0 0 78 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M34.123 60.8354C36.8414 54.597 50.9521 22.146 55.5418 11.6134C56.4434 9.54328 57.2766 7.43117 58.3558 5.45893C60.596 1.38857 64.1066 -0.471769 68.6417 0.101718C72.7533 0.619256 75.6766 3.13701 77.3431 6.88565C78.231 8.87188 78.2173 11.2498 77.3158 13.3199C74.1467 20.6354 70.9639 27.9508 67.6309 35.1964C65.6365 39.5045 62.0576 42.4838 58.4924 45.3373C51.4029 51.0162 43.6987 55.758 35.8032 60.164C35.4481 60.3739 35.0383 60.4858 34.123 60.8354Z" />
      <path d="M0 60.8354C2.71833 54.597 16.8291 22.146 21.4188 11.6134C22.3203 9.54328 23.1536 7.43117 24.2327 5.45893C26.473 1.38857 29.9836 -0.471769 34.5187 0.101718C38.6303 0.619256 41.5535 3.13701 43.22 6.88565C44.1079 8.87188 44.0943 11.2498 43.1927 13.3199C40.0236 20.6354 36.8408 27.9508 33.5078 35.1964C31.5135 39.5045 27.9346 42.4838 24.3693 45.3373C17.2798 51.0162 9.57561 55.758 1.68017 60.164C1.33867 60.3739 0.928876 60.4858 0 60.8354Z" />
    </svg>
  )
}

export default Quotation
