import ButtonStandard from '../components/atoms/ButtonStandard'
import { Helmet } from 'react-helmet'
import Layout from '../layout/Layout'
import Quotation from '../components/atoms/Quotation'
import React from 'react'

const PageNotFound = (): JSX.Element => {
  return (
    <>
      <Helmet htmlAttributes={[{ lang: 'en' }]}>
        <title>Sayers Group - 404</title>
        <meta name="msvalidate.01" content="DCA06C782ADDDB0B6E1250E3BA1B5CBA" />
        <meta name="description" content="Page not found." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout transparent={true} darkMode={true}>
        <section className="page-not-found dark-mode">
          <div className="page-not-found__inner container">
            <div className="page-not-found__wrapper">
              <Quotation className="page-not-found__quotation" />
              <h1 className="page-not-found__four-oh-four">404</h1>
              <p className="page-not-found__description">We can&apos;t find the page you&apos;re looking for.</p>
              <ButtonStandard href="/" text="Sayers Home" isExternalLink={false} />
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default PageNotFound
